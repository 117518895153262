if (window.matchMedia('(max-width: 767px)').matches) {
  var splide = new Splide( '.splide', {
    perPage: 1,
    gap:13,
    autoWidth: true,
    focus:'center'
  } );
} else {
  var splide = new Splide( '.splide', {
    perPage: 4,
    gap:20
  } );
}

// splide
$(document).ajaxStop(function() {
  splide.mount();
})


// news
function wpNews() {
  var wp_url = './wp-json/wp/v2/news/?_embed';
  var wp_nothumbnail = './assets/img/news/dummy.png';
  $.ajax({
    type: 'GET',
    url: wp_url,
    dataType: 'json'
  }).done(function(json) {
    var wp_html = '';
    //記事の件数分イテレートする
    $.each(json, function(i, item) {
      //ブログのタイトル
      var wp_title = item.title.rendered;
      //ブログのURL
      var wp_link = item.link;
      //ブログの日にち
      var wp_date_data = new Date(item.date);
      var wp_year = wp_date_data.getFullYear();
      var wp_month = ('0' + (wp_date_data.getMonth() + 1)).slice(-2);
      var wp_day = ('0' + wp_date_data.getDate()).slice(-2);
      var wp_date = wp_year + '.' + wp_month + '.' + wp_day;
      //ブログのターム名
      var wp_category = item['_embedded']['wp:term'][0][0]['name']
      //サムネイル画像のURL
      var wp_thumbnail
      if (item['_embedded']['wp:featuredmedia']) {
        wp_thumbnail = item['_embedded']['wp:featuredmedia'][0]['media_details']['sizes']['full']['source_url']
      } else(wp_thumbnail = wp_nothumbnail)
      // li以下
      wp_html += '<li class="splide__slide list-item"><a href="' + wp_link + '">';
      wp_html += '<div class="list-item__img"><img src="' + wp_thumbnail + '"></div>';
      wp_html += '<div class="list-item__message">';
      wp_html += '<p class="date">' + wp_date + '<span>' + wp_category + '</span></p>';
      wp_html += '<p class="txt">' + wp_title + '</p>';
      wp_html += '</div>';
      wp_html += '</a></li>';
    });
    //整形した記事の情報をページに追加する
    $('.js-newslist').append(wp_html)
  }).fail(function(json) {
    console.error('記事取得に失敗しました。')
  });
}

wpNews();